<form [formGroup]="filtroVeiculosForm">
  <div class="btn-filtrar-top" *ngIf="isMobileLayout">
    <button
      class="bg-transparent cor-primary upc s1 flex flex-items-center pa8 pl16 pr16 press"
      type="button"
      (click)="toggleMenuFiltroMobile()"
    >
      <p class="fw600 w-100">Filtrar</p>
    </button>
    <hr class="container_linha" />
  </div>
  <div class="container_ordenar">
    <button
      class="cor-primary s2 fw600 udl press bg-transparent"
      type="button"
      (click)="irParaEstoqueCompleto()"
    >
      {{ "PAGE.FILTER.COMPLETO" | translate }}
    </button>
    <div class="container-filtros-top">
      <!-- INPUT BUSCA -->
      <div
        class="container_busca"
        style="margin-bottom: 0"
        *ngIf="isMobileLayout"
      >
        <div style="display: flex; align-items: center">
          <input
            class="wrapperInput pl16 w-100 cor-black fw400"
            type="text"
            placeholder="Buscar por modelo"
            formControlName="modelo"
          />

          <button
            class="bg-primary hv-bg-black searchIcon"
            (click)="buscarModelo(form['modelo'].value)"
          >
            <img
              src="../../../../assets/images/search.svg"
              alt="ícone de busca"
            />
          </button>
        </div>
      </div>
      <!-- INPUT BUSCA -->

      <div class="wrapperSelect recente">
        <select
          formControlName="ordem"
          name="ordens"
          id="ordens"
          class="w-100 cor-black fw400"
          (ngModelChange)="ordenarPor(form['ordem'])"
        >
          <option *ngFor="let listarPor of listaOrdem" [value]="listarPor.id">
            {{ listarPor.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div class="container_geral">
    <div
      class="colunaFiltro flex flex-column fechado"
      [ngClass]="this.menuService.menuFiltroMobileOpen ? 'aberto' : 'fechado'"
    >
      <div
        class="filtros flex flex-items-center bg-primary pa16"
        *ngIf="isMobileLayout"
      >
        <button
          class="flex flex-items-center flex-justify-center bg-white brfull press mr16"
          (click)="toggleMenuFiltroMobile()"
        >
          <img src="../../../../assets/images/times.svg" alt="" />
        </button>
        <p class="s4 fw500 cor-white">Filtros</p>
      </div>
      <div class="box">
        <div class="container">
          <div class="container_filtro">
            <ng-container *ngIf="filtroVeiculos$ | async as filtros">
              <div class="container_busca" *ngIf="!isMobileLayout">
                <div style="display: flex; align-items: center">
                  <input
                    type="text"
                    placeholder="Buscar por modelo"
                    formControlName="modelo"
                    (keyup)="buscarModelo(form['modelo'].value)"
                    class="wrapperInput pl16 w-100 cor-black fw400"
                  />
                  <button
                    class="bg-primary hv-bg-black searchIcon"
                    (click)="buscarModelo(form['modelo'].value)"
                  >
                    <img
                      src="../../../../assets/images/search.svg"
                      alt="ícone de busca"
                    />
                  </button>
                </div>
              </div>
              <!-- INPUT BUSCA CIDADE ESTADO -->
              <!-- <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary" style>
                  LOCALIZAÇÃO
                </p>
                <hr class="container_linha" />
                <div style="display: flex; align-items: center">
                  <input
                    type="text"
                    placeholder="Buscar por cidade/estado"
                    formControlName="localizacao"
                  />
                  <div
                    class="searchIcon"
                    (click)="
                      isMinimumSearchLength() ? clearSearchCityState() : null
                    "
                  >
                    <img
                      *ngIf="!isMinimumSearchLength(); else clear"
                      src="../../../../assets/images/search.svg"
                      alt="ícone de busca"
                    />

                    <ng-template #clear>
                      <button class="clear-button">X</button>
                    </ng-template>
                  </div>
                </div>
                <ul class="searchList" *ngIf="isSearchCidadeEstadoOpen">
                  <span class="searchList_text"><b>Cidades</b></span>
                  <ng-container
                    *ngFor="let cidade of searchCidadeEstado.cidades"
                  >
                    <li
                      class="searchList_item"
                      (click)="filtraPorCidade(cidade.cidade)"
                    >
                      <a class="searchList_link">
                        {{ cidade.cidade | uppercase }}
                      </a>
                    </li>
                  </ng-container>
                  <span class="searchList_text"><b>Estados</b></span>
                  <ng-container
                    *ngFor="let estado of searchCidadeEstado.estados"
                  >
                    <li
                      class="searchList_item"
                      (click)="filtraPorEstado(estado.estado)"
                    >
                      <a class="searchList_link">
                        {{ estado.estado | uppercase }}
                      </a>
                    </li>
                  </ng-container>

                  <ng-container *ngIf="isCityStateSearchEmpty()">
                    <li class="searchList_sem-item">
                      Nenhum veículo encontrado
                    </li>
                  </ng-container>
                </ul>
              </div> -->
              <!-- INPUT MARCA -->
              <!-- <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">
                  {{ "PAGE.FILTER.MARCA" | translate }}
                </p>
                <hr class="container_linha" />

                <div *ngFor="let marca of getSelectedMarcas()">
                  <div class="marca-escolhida-card">
                    <img [src]="getMarcaImage(marca)" [alt]="marca" />
                    <div class="marca-escolhida-options">
                      <div
                        (click)="buscarMarcas(marca)"
                        class="marca-escolhida-option"
                        style="font-weight: bold"
                      >
                        <p>{{ marca }}</p>
                        <button class="lixeira-button"></button>
                      </div>
                      <div
                        (click)="
                          getModeloDaMarca(marca)
                            ? removeModelosDaMarca(marca)
                            : openModelo(marca)
                        "
                        class="marca-escolhida-option"
                      >
                        {{
                          getModeloDaMarca(marca)
                            ? getModeloDaMarca(marca)
                            : "Ver modelos"
                        }}

                        <button
                          class="ver-modelos-button"
                          *ngIf="!getModeloDaMarca(marca)"
                        ></button>
                        <button
                          class="lixeira-button"
                          *ngIf="getModeloDaMarca(marca)"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="marca-container">
                  <ng-container *ngFor="let marca of marcasNaOrdem.slice(0, 5)">
                    <div (click)="buscarMarcas(marca)" class="marca-card">
                      <img [src]="getMarcaImage(marca)" [alt]="marca" />

                      {{ marca | uppercase }}
                    </div>
                  </ng-container>
                </div>
                <div
                  (click)="isMarcasOpen = true"
                  *ngIf="marcasNaOrdem.length > 5"
                  class="ver-implementos"
                  style="font-weight: bold; margin-top: 15px"
                >
                  <p>Ver mais marcas</p>
                  <button class="ver-implementos-button"></button>
                </div>
              </div> -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">
                  {{ "PAGE.FILTER.MARCA" | translate }}
                </p>
                <hr class="container_linha" />
                <ng-container formArrayName="marcas">
                  <ng-container
                    *ngFor="
                      let marca of marcasFormArray.controls;
                      let i = index
                    "
                  >
                    <div class="container_checkbox">
                      <label class="cor-black container_checkboxLabel">
                        <input
                          type="checkbox"
                          [formControlName]="i"
                          (click)="buscarMarcas(marca, this.marcasNaOrdem[i])"
                        />
                        {{ this.marcasNaOrdem[i] | uppercase }}
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
              </div>

              <!-- INPUT LOJA -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">
                  {{ "PAGE.FILTER.LOJA" | translate }}
                </p>
                <hr class="container_linha" />
                <select
                  formControlName="filial"
                  class="w-100 pt8 pb8 pl16 pr16 s2 cor-black fw400"
                  (change)="buscarLoja([form['filial'].value])"
                >
                  <option [ngValue]="null">
                    {{ "PAGE.FILTER.TODOS" | translate }}
                  </option>
                  <option *ngFor="let loja of filtros.filiais">
                    {{ loja }}
                  </option>
                </select>
              </div>

              <!-- INPUT EIXOS -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">
                  {{ "PAGE.FILTER.EIXOS" | translate }}
                </p>
                <hr class="container_linha" />
                <div class="container_eixos-wrapper">
                  <ng-container *ngFor="let eixo of filtros.eixos">
                    <div class="container_eixos">
                      <input
                        [value]="eixo || '--'"
                        formControlName="eixo"
                        type="radio"
                        [id]="eixo || '--'"
                        name="eixos"
                        (click)="buscarEixo(eixo)"
                      />
                      <label class="mb16 cor-black" [for]="eixo || '--'">{{
                        eixo || "--"
                      }}</label>
                    </div>
                  </ng-container>
                </div>
              </div>
              <!-- INPUT PRECO -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">PREÇO</p>
                <hr class="container_linha" />
                <b class="mb16">Preço (R$)</b>
                <div class="inputNumerico">
                  <div class="width-40">
                    <input
                      formControlName="precoInicio"
                      class="cor-black fw400 mb16"
                      placeholder="de"
                      type="text"
                      (keyup)="mascaraPrecoInicio()"
                    />
                    <p>ex: 250.000</p>
                  </div>
                  <div class="width-40">
                    <input
                      formControlName="precoFim"
                      class="cor-black fw400 mb16"
                      placeholder="até"
                      type="text"
                      (keyup)="mascaraPrecoFim()"
                    />
                    <p>ex: 300.000</p>
                  </div>
                  <div>
                    <ng-container *ngIf="!temPreco">
                      <button
                        class="filtroBtn"
                        type="button"
                        (click)="
                          filtrarPreco(
                            form['precoInicio'].value,
                            form['precoFim'].value
                          )
                        "
                      >
                        >
                      </button>
                    </ng-container>
                    <ng-container *ngIf="temPreco">
                      <button
                        class="limparFiltroBtn"
                        type="button"
                        (click)="limparFiltroPreco()"
                      >
                        X
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- INPUT ANO -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">ANO</p>
                <hr class="container_linha" />
                <b class="mb16">Ano/Modelo</b>
                <div class="inputNumerico">
                  <div class="width-40">
                    <input
                      formControlName="anoInicio"
                      class="cor-black fw400 mb16"
                      placeholder="de"
                      type="text"
                    />
                    <p>ex: 2014</p>
                  </div>
                  <div class="width-40">
                    <input
                      formControlName="anoFim"
                      class="cor-black fw400 mb16"
                      placeholder="até"
                      type="text"
                    />
                    <p>ex: 2019</p>
                  </div>
                  <div>
                    <ng-container *ngIf="!temAno">
                      <button
                        class="filtroBtn"
                        type="button"
                        [disabled]="!filtroVeiculosForm.valid"
                        (click)="
                          filtrarAno(
                            form['anoInicio'].value,
                            form['anoFim'].value
                          )
                        "
                      >
                        >
                      </button>
                    </ng-container>
                    <ng-container *ngIf="temAno">
                      <button
                        class="limparFiltroBtn"
                        type="button"
                        (click)="limparFiltroAno()"
                      >
                        X
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- INPUT QUILOMETRAGEM -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">
                  QUILOMETRAGEM
                </p>
                <hr class="container_linha" />
                <b class="mb16">Quilometragem</b>
                <div class="inputNumerico">
                  <div class="width-40">
                    <input
                      formControlName="kmInicio"
                      class="cor-black fw400 mb16"
                      placeholder="de"
                      type="text"
                      (keyup)="mascaraKmInicio()"
                      [value]="form['kmInicio']"
                    />
                    <p>ex: 10.000</p>
                  </div>
                  <div class="width-40">
                    <input
                      formControlName="kmFim"
                      class="cor-black fw400 mb16"
                      placeholder="até"
                      type="text"
                      (keyup)="mascaraKmFim()"
                      [value]="form['kmFim']"
                    />
                    <p>ex: 50.000</p>
                  </div>
                  <div>
                    <ng-container *ngIf="!temKm">
                      <button
                        class="filtroBtn"
                        type="button"
                        (click)="
                          filtrarKm(form['kmInicio'].value, form['kmFim'].value)
                        "
                      >
                        >
                      </button>
                    </ng-container>
                    <ng-container *ngIf="temKm">
                      <button
                        class="limparFiltroBtn"
                        type="button"
                        (click)="limparFiltroKm()"
                      >
                        X
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- INPUT PLACA -->
              <ng-container
                *ngIf="
                  categoriaSelecionada != 'implementos' &&
                  categoriaSelecionada != 'maquinas-e-equipamentos-agricolas'
                "
              >
                <div class="container_opcoes">
                  <p class="s4 fw500 upc cor-gray hv-cor-primary">
                    FINAL DA PLACA
                  </p>
                  <hr class="container_linha" />
                  <ng-container formArrayName="placas">
                    <ng-container
                      *ngFor="
                        let placa of placasFormArray.controls;
                        let i = index
                      "
                    >
                      <div class="container_checkbox">
                        <label class="cor-black container_checkboxLabel">
                          <input
                            type="checkbox"
                            [formControlName]="i"
                            (click)="buscarPlacas(placa, this.placasNaOrdem[i])"
                          />
                          {{ this.placasNaOrdem[i] | uppercase }}
                        </label>
                      </div>
                    </ng-container>
                  </ng-container>
                </div>
              </ng-container>
              <!-- INPUT IMPLEMENTOS -->
              <div class="container_opcoes implementos-checkbox-container">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">DIFERENCIAIS</p>
                <hr class="container_linha" />
                <ng-container formArrayName="implementos">
                  <ng-container
                    *ngFor="
                      let implemento of getImplementosPreview();
                      let i = index
                    "
                  >
                    <div class="container_checkbox">
                      <label class="cor-black container_checkboxLabel">
                        <input
                          type="checkbox"
                          [formControlName]="i"
                          (click)="
                            buscarImplementos(implemento, this.implementos[i])
                          "
                        />
                        {{ this.implementos[i] | uppercase }}
                      </label>
                    </div>
                  </ng-container>
                </ng-container>

                <div
                  (click)="isImplementosOpen = true"
                  class="ver-implementos"
                  style="font-weight: bold; margin-top: 15px"
                >
                  <p>Ver mais diferenciais</p>
                  <button class="ver-implementos-button"></button>
                </div>
              </div>
              <!-- INPUT COR -->
              <div class="container_opcoes">
                <p class="s4 fw500 upc cor-gray hv-cor-primary">CORES</p>
                <hr class="container_linha" />
                <ng-container formArrayName="cores">
                  <ng-container
                    *ngFor="let cor of coresFormArray.controls; let i = index"
                  >
                    <div class="container_checkbox">
                      <label class="cor-black container_checkboxLabel">
                        <input
                          type="checkbox"
                          [formControlName]="i"
                          (click)="buscarCores(cor, this.coresNaOrdem[i])"
                        />
                        {{ this.coresNaOrdem[i] | uppercase }}
                      </label>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
              <!-- Botão filtrar Desk -->
              <div class="botoesFiltro" *ngIf="!isMobileLayout">
                <button
                  class="limparTodosFiltrosBtn pa16 pl48 pr48"
                  type="button"
                  (click)="limparFiltros()"
                >
                  Limpar Filtros
                </button>
              </div>
              <!-- Botão filtrar Mobile -->
              <div
                class="btn-filtrar flex w-100 pt32 mb32"
                *ngIf="isMobileLayout"
                (click)="toggleMenuFiltroMobile()"
              >
                <button
                  class="bg-primary hv-bg-secondary s3 cor-white brfull pa16 pl48 pr48 press"
                  type="button"
                >
                  Filtrar
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="container_resultados">
      <ng-container *ngIf="veiculos as pdvData; else loading">
        <div class="w-100 gridProdutos">
          <app-offer-card
            *ngFor="
              let veiculo of veiculos
                | paginate
                  : {
                      id: 'paginador',
                      itemsPerPage: 12,
                      currentPage: paginacao.paginaAtual,
                      totalItems: paginacao.itensTotais
                    }
            "
            class="w-100"
            [produtofiltro]="veiculo"
          >
          </app-offer-card>
        </div>
        <div
          *ngIf="veiculos.length <= 0"
          class="flex flex-column flex-justify-center flex-items-center w-100"
          style="gap: 32px"
        >
          <p class="s2 cor-black">Ops... Não encontramos opções.</p>
          <button
            class="cor-primary s2 fw600 udl hv-cor-black press bg-transparent"
            (click)="irParaEstoqueCompleto()"
          >
            {{ "PAGE.FILTER.COMPLETO" | translate }}
          </button>
        </div>
        <div
          *ngIf="veiculos.length >= 1"
          class="flex w-100 flex-items-center flex-justify-center mt32"
        >
          <pagination-controls
            id="paginador"
            [maxSize]="10"
            [responsive]="true"
            (pageChange)="pageChanged($event)"
            previousLabel="{{ 'PAGINACAO.ANTERIOR' | translate }}"
            nextLabel="{{ 'PAGINACAO.PROXIMO' | translate }}"
            screenReaderPaginationLabel="{{
              'PAGINACAO.SCREENREADERPAGINATIONLABEL' | translate
            }}"
            screenReaderPageLabel="{{
              'PAGINACAO.SCREENREADERPAGELABEL' | translate
            }}"
            screenReaderCurrentLabel="{{
              'PAGINACAO.SCREENREADERCURRENTLABEL' | translate
            }}"
          >
          </pagination-controls>
        </div>
      </ng-container>
      <ng-template #loading>
        <app-loading></app-loading>
      </ng-template>
    </div>
  </div>

  <div class="drawer" [class.no-drawer]="!isImplementosOpen">
    <div class="container-drawer">
      <div class="header">
        <div class="header-row">
          <button
            class="back-button"
            (click)="closeImplementosSidebar()"
          ></button>
          <h3>Diferenciais</h3>
        </div>

        <input
          type="text"
          placeholder="Buscar por implemento"
          (keyup)="searchImplementos($event)"
          class="wrapperInput pl16 w-100 cor-black fw400"
        />
      </div>

      <hr />
      <div class="drawer-block">
        <h4>Todos os diferenciais</h4>
      </div>

      <div class="drawer-list-container" style="padding: 5px 15px">
        <ng-container formArrayName="implementos">
          <ng-container
            *ngFor="
              let implemento of filteredImplementosControls;
              let i = index
            "
          >
            <div class="container_checkbox">
              <label class="cor-black container_checkboxLabel">
                <input
                  type="checkbox"
                  [formControlName]="i"
                  (click)="
                    buscarImplementos(implemento, this.filteredImplementos[i])
                  "
                />
                {{ this.filteredImplementos[i] | uppercase }}
              </label>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- <div class="drawer" [class.no-drawer]="!isMarcasOpen">
    <div class="container-drawer">
      <div class="header">
        <div class="header-row">
          <button class="back-button" (click)="closeMarcasSidebar()"></button>
          <h3>Marcas</h3>
        </div>
      </div>

      <hr />
      <div class="drawer-block">
        <h4>Todas as marcas</h4>
      </div>

      <div class="drawer-list-container" style="padding: 5px 15px">
        <div class="marca-container">
          <ng-container *ngFor="let marca of marcasNaOrdem">
            <div (click)="buscarMarcas(marca)" class="marca-card">
              <img [src]="getMarcaImage(marca)" [alt]="marca" />

              {{ marca | uppercase }}
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div> -->
</form>

<!-- <div class="drawer" [class.no-drawer]="!isModelosOpen">
  <div class="container-drawer">
    <div class="header">
      <div class="header-row">
        <button class="back-button" (click)="closeModelo()"></button>
        <h3>Modelos</h3>
      </div>

      <input
        type="text"
        placeholder="Buscar por modelo"
        (keyup)="searchModelosMarca($event, currentMarca)"
        class="wrapperInput pl16 w-100 cor-black fw400"
      />
    </div>

    <hr />
    <div class="drawer-block">
      <h4>Todos os modelos {{ currentMarca }}</h4>
    </div>

    <div class="drawer-list-container">
      <div *ngFor="let modelo of filteredModelosPorMarca[currentMarca]">
        <p
          (click)="addModelo({ marca: currentMarca, modelo })"
          class="modelo-button"
        >
          {{ modelo }}
        </p>
        <div class="line"></div>
      </div>
    </div>
  </div>
</div> -->
